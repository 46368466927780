import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";

const Home = lazy(() => import('./pages/Home'));
const Tattoo = lazy(() => import('./pages/Tattoo'));
const MedSpa = lazy(() => import('./pages/MedSpa'));
const Acne = lazy(() => import('./pages/Acne'));
const NotFound = lazy(() => import('./pages/NotFound'));



function App() {
    return (
        <div>
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/tattoo">
                            <Tattoo />
                        </Route>
                        <Route exact path="/med_spa">
                            <MedSpa />
                        </Route>
                        <Route exact path="/acne">
                            <Acne />
                        </Route>

                        <Route path="/404">
                            <NotFound />
                        </Route>

                        <Redirect to="/404" />
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
